import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import SEO from '../components/organisms/seo';
import Section from '../components/modules/section';
import HeaderSubPage from '../components/molecules/headerSubPage';
import Join from '../components/modules/join';
import { ease } from '../utils/easing';
import { media } from '../utils/media-queries';

const CareersPage = ({ data }) => {
  const { headline, content } = data.careers.nodes[0];
  const lastItem = content.length;

  return (
    <>
      <SEO siteTitle="Careers" />
      <HeaderSubPage headline={headline} className="careers" />
      <StyledCareers
        initial={{ y: 500 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.9, ease: ease.inOutSnap }}
      >
        {content.map(
          (contentItem, i) =>
            (contentItem.__typename === 'SanityCareersSection' && (
              <Section
                key={i}
                headline={contentItem.headline}
                content={contentItem.content}
                last={i + 1 === lastItem}
              />
            )) ||
            (contentItem.__typename === 'SanityJoin' && (
              <Join
                key={i}
                headline={contentItem.headline}
                positions={contentItem.positions}
                description={contentItem._rawDescription}
              />
            ))
        )}
      </StyledCareers>
    </>
  );
};

const StyledCareers = styled(motion.article)`
  background: var(--white);
  margin-left: var(--sidebar-width);

  @media ${media.M} {
    margin-left: var(--sidebar-width-at-m);
  }
`;

export const query = graphql`
  query Careers {
    careers: allSanityCareers {
      nodes {
        headline
        content {
          ... on SanityCareersSection {
            __typename
            headline
            content {
              ... on SanityCopy {
                __typename
                _rawCopy
              }
              ... on SanityMainImage {
                __typename
                image {
                  asset {
                    gatsbyImageData
                  }
                }
                alt
                caption
              }
              ... on SanityImageGallery {
                __typename
                images {
                  alt
                  caption
                  image {
                    asset {
                      gatsbyImageData
                    }
                  }
                }
              }
              ... on SanityPublications {
                __typename
                publication {
                  url
                  subline
                  headline
                }
              }
              ... on SanityAccordion {
                __typename
                accordion {
                  description
                  headline
                }
              }
              ... on SanityTeam {
                __typename
                team {
                  headline
                  teamMembers {
                    linkedin
                    name
                    position
                    _rawDescription
                    portrait {
                      asset {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
              ... on SanityValues {
                __typename
                values {
                  description
                  headline
                }
              }
              ... on SanityNumbers {
                __typename
                number {
                  value
                  unit
                  description
                }
              }
              ... on SanityHistory {
                __typename
                timeline {
                  year
                  content {
                    alt
                    caption
                    month
                    image {
                      asset {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
              ... on SanityCta {
                __typename
                title
                link {
                  ... on SanityLinkPage {
                    __typename
                    page
                  }
                  ... on SanityLinkUrl {
                    __typename
                    url
                  }
                }
              }
            }
          }
          ... on SanityJoin {
            __typename
            headline
            _rawDescription
            positions {
              name
              position
              url
            }
          }
        }
      }
    }
  }
`;

CareersPage.propTypes = {
  data: PropTypes.object,
};

export default CareersPage;
